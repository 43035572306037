.verticalCenter {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftchild {
  background-image: url('./Images/LeftPage.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rightchild {
  background-image: url('./Images/RightPage.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  margin-bottom: 350px;
  text-align: center;
  font-size: large;
}

.redtext {
  color: red;
}

.lockimage{
  margin-bottom: 0px;
}

.loginForm{
  margin-bottom: 200px;
}