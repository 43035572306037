.topbaricons {
    font-size: larger;
}

.serchfield {
    padding: 2px;
    border-radius: 5px;
    color: white;
    background-color: rgb(217, 217, 217);
}


.serchfield input {
    width: 90%;
    background-color: rgb(217, 217, 217);
}

.serchfield input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1;
    /* Firefox */
}

.serchfield input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: black;
}

.serchfield input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
}

.userProfile {
    border: 1px solid black;
}

.profileimage {
    border-radius: 100px;
    border-color: black;
    border-width: 20px;
}


.filters {
    background-color: grey;
    text-align: center;
    border-radius: 5px;
    color: white;
}

.logoutbtn {
    background-color: gray;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    text-align: center;
    vertical-align:middle;
}