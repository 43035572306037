
.reportsTable table {
    background-color: rgb(206, 206, 206);
    background-repeat: no-repeat;
    background-size: cover;
}

.reportsTable table tr th,
.reportsTable table tr td {
    border-color: black;
}

.ReportsTitle {
    text-align: center;
}
