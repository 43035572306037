#sideMenuItems {
    list-style-type: none;
}


#sideMenuItems li {
    font-weight: bold;
    color: grey;
    cursor: pointer;
    margin-top: 10px;
}

#sideMenuItems li:hover {
    font-weight: bold;
    color: rgb(170, 88, 88);
    cursor: pointer;
    margin-top: 10px;
}

.pageselected {
    background-color: grey;
    color: white !important;
    padding: 8px;
    border-radius: 10px;
}

.ReportsListHidden{
    display: none;
}

.ReportsListShown{
    display: block;
}

