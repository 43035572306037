#sidemenu {
    background-color: rgb(230, 230, 230);
}

.maindashboardbody {
    background-image: url('./Images/Dashboard-Background.jpg');
    background-repeat: repeat;
    background-size: cover;
    overflow: hidden;
}

.MainContentContainer {
    min-height: 100vh;
}

.Pichart {
    border-right: 1px solid grey;
    height: 150px;
}

